<template>
	<div>

		<div>
			<div class="d-flex my-3 a-center">
				<div style="font-size: 14px;width:110px;">用户名称：</div>
				<input type="text" v-model="userone.username" style="font-size: 14px;margin-right: 10px;" >
				<div @click="tjbc" class="d-flex a-center j-center bg" style="width:110px;height: 36px;border-radius: 10px;">
					<div style="font-size: 14px;">保存名称</div>
				</div>
			</div>
			<div v-if="userone.phone">
				<div style="font-size: 10px;color: #0075f3;">当前账号已完成号码绑定（绑定号码后，账号兼容电话号码登录，支持多端数据互通使用）</div>
				<div class="d-flex my-3 a-center">
					<div style="font-size: 14px;width:110px;">绑定电话：</div>
					<input type="text" v-model="userone.phone" style="font-size: 14px; margin-right: 10px;width: 100%;" disabled="disabled">
					<!-- <div @click="getCode" class="d-flex a-center j-center" style="width: 120px;height: 36px;border-radius: 10px;" :class="codeTime > 0 ? 'bgs' : 'bg'">
									<div style="font-size: 14px;">{{codeTime > 0 ? codeTime + ' s' : '获取验证码'}}</div>
								</div> -->
				</div>
			</div>
			
			
			<div v-else>
				<div style="font-size: 10px;color: #0075f3;">绑定号码领取20积分（绑定号码后，账号兼容电话号码登录，支持多端数据互通使用）</div>
				<div class="d-flex my-3 a-center">
					<div style="font-size: 14px;width:110px;">绑定电话：</div>
					<input type="text" v-model="phone" style="font-size: 14px; margin-right: 10px;">
					<div @click="getCode" class="d-flex a-center j-center" style="width: 110px;height: 36px;border-radius: 10px;" :class="codeTime > 0 ? 'bgs' : 'bg'">
						<div style="font-size: 14px;">{{codeTime > 0 ? codeTime + ' s' : '获取验证码'}}</div>
					</div>
				</div>
				<div class="d-flex my-3 a-center">
					<div style="font-size: 14px;width:110px;">验证号码：</div>
					<input type="text" v-model="yzm" placeholder="请输入验证码" style="font-size: 14px; margin-right: 10px;">
					<div @click="tjbd" class="d-flex a-center j-center bg" style="width: 110px;height: 36px;border-radius: 10px;" v-if="yzm.length == 6">
						<div style="font-size: 14px;">提交绑定</div>
					</div>
				</div>
			</div>
			

		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		data() {
			return {
				userone:{},
				phone:'',
				yzm:'',
				codeTime:0,
			}
		},
		computed: {
			...mapState({
				user: state => state.user.user,
				token: state => state.user.token
			}),
		},
		// 页面初始化
		created(){
			this.getData()
		},
		methods: {
			getData() {
				this.axios.get('/admin/myuser/'+this.user.id,{
					token:true
				}).then(res=>{
					this.userone = res.data.data
				})
			},
			// 获取验证码
			getCode(){
				// 防止重复获取
				if (this.codeTime > 0) {
					return;
				}
				// 请求数据
				this.axios.post('/admin/sendcode',{
					phone:this.phone
				},{
					token:true
				}).then(res=>{
					this.$message({
					    message: res.data.data,
					    type: 'warning'
					});
					// 倒计时
					this.codeTime = 60
					let timer = setInterval(()=>{
						if (this.codeTime >= 1) {
							this.codeTime--
						} else {
							this.codeTime = 0
							clearInterval(timer)
						}
					},1000)
				})
			},
			tjbc(){
				// this.axios.post('/admin/kcdh',data,{ token:true }).then(res => {
				// 	this.dhlist.push(res.data.data)
				// 	this.dhlist = this.tabless(this.dhlist)
				// 	this.ddz = 0
				// 	this.setData(this.dhlist.slice(-1)[0])
				// });
			},
			tjbd(){
				let data = {
					user_id:this.user.id,
					phone:this.phone
				}
				this.axios.post('/admin/bdhm',data,{ token:true }).then(res => {
					this.$message({
					    message: res.data.data,
					    type: 'warning'
					});
				});
			}
		},
	}
</script>

<style scoped>
	.a-center{
		align-items: center;
	}
	.j-center{
		justify-content: center;
	}
	input{
	    resize: none;
	    width: 300px;
	    border: none;
	    padding: 8px;

	    margin: 0;
	    background-color: #fff;
	    border-radius: 8px;
	}
	
	.bg{
		background: rgb(37, 193, 149);
		color: #ffffff;
	}
	.bgs{
		background: rgb(0, 145, 255);
		color: #ffffff;
	}
</style>